import { Authorizer, useAuthorizer } from "@authorizerdev/authorizer-react";
import { Container, Typography } from "@mui/material";
import { navigate } from "gatsby";
import React from "react";
import { useInsertUserMutation } from "../__generated__/types";

const LoginPage = () => {
  const { loading, user } = useAuthorizer();
  React.useEffect(() => {
    if (!loading && user) {
      navigate("/");
    }
  }, [loading, user]);

  const createStripeCustomer = async ({ email }) => {
    const customer = await (
      await fetch("api/stripe/customer-create", {
        method: "POST",
        body: JSON.stringify({ email }),
      })
    ).json();
    return customer;
  };
  const [insertUser] = useInsertUserMutation();
  return (
    <Container
      maxWidth="sm"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography variant="h1" align="center" gutterBottom>
        Villy
      </Typography>
      <Authorizer
        onSignup={async (data) => {
          const customer = await createStripeCustomer({
            email: data?.user.email,
          });

          await insertUser({
            variables: {
              object: { id: data?.user.id, stripe_customer_id: customer.id },
            },
          });
          navigate("/");
        }}
        onLogin={async (data) => {
          navigate("/");
        }}
      />
    </Container>
  );
};

export default LoginPage;
